import { io } from 'socket.io-client';
import { store } from '../Redux/store';
import { setFinancingState } from '../Redux/slices/loader.slice';

const URL = process.env.REACT_APP_SOCKET_URL || '';

export const socket = io(URL, {
    path: '/api/socket.io', // Ensure your server supports this path
    autoConnect: false,
    transports: ['websocket']
});

export const openSocket = () => {
    if (!socket.connected) {
        socket.connect(); // Only connect if it's not already connected
        socket.on('connect', () => {
            console.log('Socket connected:', socket.id);
        });
        socket.on('connect_error', (error) => {
            console.error('Connection failed:', error.message);
        });
        socket.on('CONTINUE_FINANCE_ON_BUSINESS', (data: any) => {
            console.log(data)
            store.dispatch(setFinancingState(data));
            // expire link here
        })
    } else {
        console.log('Socket already connected:', socket.id);
    }
    console.log(socket.connected);
};

export const disconnectSocket = () => {
    // if (socket.connected) {
    //     socket.disconnect(); // Disconnect only if already connected
    // }
};