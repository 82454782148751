import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import paymentDetailsReducer from './slices/payment.slice';
import { LoaderReducer } from './slices/loader.slice';

const rootReducer = combineReducers({
  paymentDetails: paymentDetailsReducer,
  loaderReducer: LoaderReducer
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['paymentDetails'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

export const persistor: Persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
