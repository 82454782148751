import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FinanceOnBusinessState } from "../../service/shared.service";

interface LoaderState {
    loader: boolean;
    financeOnBusinessState: FinanceOnBusinessState;
}

const initialState: LoaderState = {
    loader: false,
    financeOnBusinessState: {
      contract_patient_token: ''
    }
};

const loaderSlice = createSlice({
    name: 'loaderState',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loader = action.payload;
        },
        setFinancingState: (state, action: PayloadAction<FinanceOnBusinessState>) => {
            console.log('action.payload', action.payload)
            state.financeOnBusinessState = action.payload;
        }
    }
})

export const {setLoading, setFinancingState} = loaderSlice.actions;

export const LoaderReducer = loaderSlice.reducer;
