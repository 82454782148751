import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import LayoutLazyComponent from './components/LayoutComponent/LayoutComponent.lazy';
import InvalidComponent from './components/InvalidComponent/InvalidComponent.lazy';
import HomeComponent from './components/HomeComponent/HomeComponent.lazy';
import PlanCreated from './components/PlanCreated/PlanCreated.lazy';
import LinkExpire from './components/LinkExpire/LinkExpire.lazy';


const RouterComponent: React.FC = () => {

  // Create the router instance
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<LayoutLazyComponent />}>
          <Route path="/" element={<InvalidComponent />} />
          <Route path="/invalid" element={<InvalidComponent />} />
          <Route path="/pay-now/:token" element={<HomeComponent />} />
          <Route path="/plan-created/:token" element={<PlanCreated />} />
          <Route path="/link-expire" element={<LinkExpire />} />
        </Route>
        <Route path="*" element={<InvalidComponent />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default RouterComponent;